import React, { useState } from "react";
import { graphql } from "gatsby";
import { ArrowLeft, ArrowRight } from "react-feather";
import Swiper from "react-id-swiper";
import SwiperSlide from "react-id-swiper";

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  buildImageObj,
} from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

import BlogPostPreview from "../components/blog-post-preview";
import GraphQLErrorList from "../components/graphql-error-list";
import BlockContent from "../components/block-content";
import Container from "../components/container";
import StoryBox from "../components/story-box";
import Slide2 from "../components/slide-2";
import Button from "../components/button";
import SEO from "../components/seo";

import Marquee from "../components/marquee";

import Layout from "../containers/layout";

import FocusSection from "../components/ourFocus";
import MissionStatment from "../components/missionStatmentSection";

const ParagraphClass =
  "inline-block relative w-full text-sm md:text-base text-gray-300 my-8";

const arrowButtonClass = "orange arrowClass h-10";

export const query = graphql`
 query IndexPageQuery {
  site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
    title
    description
    image {
      asset {
        _id
      }
    }
    keywords
  }
  page: sanityHome(_id: {regex: "/(drafts.|)home/"}) {
    id
    phoneGif {
      asset {
        _id
      }
    }
    title
    _rawBody
    ctaPrimary
    ctaPrimaryURL
    ctaSecondary
    ctaSecondaryURL
    stats {
      stat
      title
      description
      url
      image {
        asset {
          _id
        }
      }
    }
    slide2TL
    slide2TR
    slide2BL
    slide2LeftTitle
    slide2LeftDescription
    slide2LeftImage {
      asset {
        _id
      }
    }
    slide2RightTitle
    slide2RightDescription
    slide2RightImage {
      asset {
        _id
      }
    }
    slide2BottomLeftTitle
    slide2BottomLeftDescription
    slide2BottomLeftImage {
      asset {
        _id
      }
    }
    showStories
    showBlog
    mainCTA
    mainCTAURL
  }
  stories: allSanityStory(limit: 12, sort: {fields: [publishedAt]}) {
    edges {
      node {
        id
        mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
        logo {
          asset {
            _id
          }
          alt
        }
        title
        excerpt
        slug {
          current
        }
      }
    }
  }
  portfolio: allSanityPortfolio {
    edges {
      node {
        id
        logo {
          asset {
            _id
          }
          alt
        }
        name
        type
      }
    }
  }
  posts: allSanityPost(limit: 12, sort: {fields: [publishedAt], order: DESC}) {
    edges {
      node {
        id
        publishedAt
        mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
        title
        _rawExcerpt
        slug {
          current
        }
      }
    }
  }
}
`;

const IndexPage = (props) => {
  const { data, errors } = props;
  const [swiper, setSwiper] = useState(null);
  const [swiper1B, setSwiper1B] = useState(null);
  const [swiper2, setSwiper2] = useState(null);
  const [swiper3, setSwiper3] = useState(null);

  const [hoveredButton, setActiveImageArray] = useState(null);

  const handleHover1 = () => setActiveImageArray("button1");
  const handleHover2 = () => setActiveImageArray("button2");
  const handleHover3 = () => setActiveImageArray("button3");
  const handleMouseLeave = () => setActiveImageArray(null);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const page = (data || {}).page;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts).filter(filterOutDocsWithoutSlugs)
    : [];
  const storyNodes = (data || {}).stories
    ? mapEdgesToNodes(data.stories).filter(filterOutDocsWithoutSlugs)
    : [];
  const portfolioNodes = (data || {}).portfolio
    ? mapEdgesToNodes(data.portfolio)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  if (!page) {
    throw new Error(
      'Missing "Pages > Home". Open the studio at http://localhost:3333 and add some content to "Pages > Home" and restart the development server.'
    );
  }

  const params = {
    rebuildOnUpdate: false,
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
      1200: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
    },
    slideClass: "swiper-slide",
    getSwiper: setSwiper,
    autoplay: true,
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params1B = {
    rebuildOnUpdate: false,
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
      1080: {
        slidesPerView: 2,
        spaceBetween: 32,
      },
    },
    slideClass: "swiper-slide",
    getSwiper: setSwiper1B,
    autoplay: true,
  };

  const goNext1B = () => {
    if (swiper1B !== null) {
      swiper1B.slideNext();
    }
  };

  const goPrev1B = () => {
    if (swiper1B !== null) {
      swiper1B.slidePrev();
    }
  };

  const params2 = {
    rebuildOnUpdate: false,
    slidesPerView: 1,
    spaceBetween: 64,
    breakpoints: {
      960: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
    slideClass: "swiper-slide",
    getSwiper: setSwiper2,
    autoplay: true,
  };

  const goNext2 = () => {
    if (swiper2 !== null) {
      swiper2.slideNext();
    }
  };

  const goPrev2 = () => {
    if (swiper2 !== null) {
      swiper2.slidePrev();
    }
  };

  const params3 = {
    rebuildOnUpdate: false,
    slidesPerView: 1,
    spaceBetween: 64,
    breakpoints: {
      960: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
    slideClass: "swiper-slide",
    getSwiper: setSwiper3,
    autoplay: true,
  };

  const goNext3 = () => {
    if (swiper3 !== null) {
      swiper3.slideNext();
    }
  };

  const goPrev3 = () => {
    if (swiper3 !== null) {
      swiper3.slidePrev();
    }
  };

  const Stat = ({ stat }) => (
    <div className="flex flex-col items-center text-white w-22 border-solid border-gray-800 py-4">
      {stat.image ? (
        <img
          className="inline-block w-22 lg:w-34 h-20 lg:h-32 fit rounded-sm mb-6"
          src={imageUrlFor(buildImageObj(stat.image))}
        />
      ) : null}
      <strong className="inline-block h-auto lg:h-24 underlined relative w-full text-lg lg:text-2xl font-bold mb-4 text-center leading-tight">
        {stat.stat}
      </strong>
      {/* {stat.title ? (
        <p className="inline-block relative w-full text-base text-gray-400 font-semibold mb-4">
          {stat.title}
        </p>
      ) : null} */}

      {stat.description ? (
        <p className="inline-block relative w-full text-sm text-white">
          {stat.description}
        </p>
      ) : null}
      {stat.url ? (
        <a
          className="inline-block relative w-full text-sm text-white link underline"
          href={stat.url}
        >
          View link
        </a>
      ) : null}
    </div>
  );

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
        image={
          site.image && site.image.asset && site.image.asset._id
            ? imageUrlFor(buildImageObj(site.image))
            : false
        }
      />
      <Container>
        <div className="h-full lg:h-auto overflow-none flex flex-col lg:flex-row relative hill-background cover w-full px-10 pt-6 md:pt-32 fit object-cover z-0">
          <div className="lg:pr-10  pt-20 h-full flex flex-col overflow-none lg:w-6/12">
            <h1 className="text-6xl text-center lg:text-left leading-tight lg:text-6xl font-bold">
              Helping brands grow on Amazon
            </h1>
            <Button
              primary
              width=' lg:w-64'
              padding=" px-10 lg:px-0"
              outlined
              margin=" mb-0 md:mb-3 md:mt-10 "
              text={page.ctaPrimary}
              to={page.ctaPrimaryURL}
            />
            <p className="my-4 lg:pr-10 text-center lg:text-left">
              Cartograph guides brands to success on Amazon, Amazon Fresh, Instacart, and Retail Media. Categories we specialize in:
            </p>

            <div className="button-container flex flex-wrap lg:flex-no-wrap justify-around lg:justify-start mb-4">
              {[
                { text: 'Food & Beverage', hover: handleHover1 },
                { text: 'Beauty & Personal Care', hover: handleHover2 },
                { text: 'Health & Supplements', hover: handleHover3 }
              ].map(({ text, hover }, index) => (
                <span
                  key={index}
                  onMouseEnter={hover}
                  onMouseLeave={handleMouseLeave}
                  className="text-sm underline w-full lg:py-2 lg:mr-2 lg:w-auto lg:mr-4 text-center lg:text-left cursor-pointer">
                  {text}
                </span>
              ))}
              <span className="text-sm lg:py-2 w-full lg:w-auto block text-center lg:text-left"><i>And more...</i></span>
            </div>
            <div className="hidden lg:flex w-full">
            </div>
          </div>
          <div className="px-4  lg:mt-10  relative mx-auto h-64 lg:h-full pb-64 lg:pb-0 z-0 w-full lg:w-1/2">
            <div className=" mx-auto relative h-64 z-0 sm:max-w-sm">
              {/* <img
                src="PhoneBorder.png"
                className="absolute mx-auto pt-px pb-32 left-0 top-0 z-10 sm:max-w-sm"
              ></img> */}
              <img
                src={imageUrlFor(buildImageObj(page.phoneGif)).url()}
                className="absolute rounded-3xl w-12/12 px-2 lg:px-6 mx-auto mt-4  lg:mt-8 left-0 top-0 z-0 sm:max-w-sm"
              ></img>
            </div>
          </div>
        </div>

        <Marquee hoveredButton={hoveredButton} brands={portfolioNodes} />
        <div className="inline-block dark-green relative  w-full pt-16 md:pt-32">
          <div className="flex flex-wrap justify-between relative w-full">
            {/* Intro */}
            <div
              className={`flex flex-col justify-center relative text-center px-6 mb-8 md:px-10 md:text-left w-full lg:w-1/2 ${storyNodes && storyNodes.length > 0 ? "lg:w-1/2" : ""
                }`}
            >
              <h1 className="inline-block leading-tight relative w-full text-5xl lg:text-6xl text-center lg:text-left font-bold">
                {page.title}
              </h1>
              {/* Story slider for mobile*/}
              {page.showStories && storyNodes && storyNodes.length > 0 ? (
                <div className="lg:hidden flex justify-center py-10  items-center w-full h-full lg:w-6/12">
                  <div className="h-full flex items-center justify-center w-1/12">
                    <ArrowLeft className={arrowButtonClass} onClick={goPrev1B} />
                  </div>
                  <div className="h-full mx-auto w-9/12">
                    <Swiper {...params1B}>
                      {storyNodes.map((story, index) => (
                        <StoryBox story={story} key={`story-${index}`} />
                      ))}
                    </Swiper>
                  </div>

                  <div className="h-full flex items-center justify-center w-1/12">
                    <ArrowRight className={arrowButtonClass} onClick={goNext1B} />
                  </div>
                </div>
              ) : null}

              <div className={ParagraphClass}>
                <BlockContent blocks={page._rawBody || []} />
              </div>
              {/* CTAS */}
              {page.ctaPrimary || page.ctaSecondary ? (
                <div className="inline-block md:flex justify-start items-center relative w-full">
                  <Button
                    primary
                    external
                    outlined
                    full
                    margin="mb-8 md:mb-4"
                    text={page.ctaSecondary}
                    to={page.ctaSecondaryURL}
                  ></Button>
                </div>
              ) : null}
            </div>
            {/* Story slider for desktop*/}
            {page.showStories && storyNodes && storyNodes.length > 0 ? (
              <div className="hidden lg:flex justify-center pb-10 px-10 items-center w-full lg:w-6/12">
                <div className="h-full flex items-center justify-center w-1/12">
                  <ArrowLeft className={arrowButtonClass} onClick={goPrev} />
                </div>
                <div className="h-full mx-auto w-9/12">
                  <Swiper {...params}>
                    {storyNodes.map((story, index) => (
                      <StoryBox story={story} key={`story-${index}`} />
                    ))}
                  </Swiper>
                </div>

                <div className="h-full flex items-center justify-center w-1/12">
                  <ArrowRight className={arrowButtonClass} onClick={goNext} />
                </div>
              </div>
            ) : null}
            {/* Stats */}
            {page.stats && page.stats.length > 0 ? (
              <div className="inline-block relative w-full py-10 mt-10 px-10 light-green">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-10 md:gap-20 my-10 relative w-full">
                  {page.stats.map((stat, index) => (
                    <Stat stat={stat} key={`stat-${index}`} />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <MissionStatment />
          {/* Slide 2 */}
          <div className="inline-block light-green px-4 py-6 lg:px-0 relative w-full pb-4 lg:py-10">
            <div className="flex flex-col md:flex-row justify-between items-center w-11/12 mx-auto lg:pb-6">
              <h2 className=" font-bold text-5xl word-break mb-6 lg:mb-6 w-10/12 lg:w-auto leading-tight text-center">Our Services</h2>
              <div className="hidden lg:flex ">
                <Button
                  external
                  primary
                  padding=" px-20 lg:px-30"
                  outlined
                  margin="my-8 md:my-0"
                  text="What We Do"
                  to="/services"
                  className="hidden md:flex"
                ></Button>
              </div>
            </div>
            <div className="flex relative px-0 items-center w-full">
              <div className="h-full flex items-center justify-center w-1/12 lg:hidden">
                <ArrowLeft className={arrowButtonClass} onClick={goPrev2} />
              </div>
              <div className="h-full w-9/12 mx-auto lg:w-11/12  ">
                <Swiper {...params2}>
                  {page.slide2TL ? (
                    <Slide2
                      description={page.slide2LeftDescription}
                      image={page.slide2LeftImage}
                      title={page.slide2LeftTitle}
                      split={true}
                      button
                    />
                  ) : null}
                  {page.slide2TR ? (
                    <Slide2
                      description={page.slide2RightDescription}
                      image={page.slide2RightImage}
                      title={page.slide2RightTitle}
                      split={true}
                      button
                    />
                  ) : null}
                  {page.slide2BL ? (
                    <Slide2
                      description={page.slide2BottomLeftDescription}
                      image={page.slide2BottomLeftImage}
                      title={page.slide2BottomLeftTitle}
                      split={true}
                      button
                    />
                  ) : null}

                </Swiper>
              </div>

              <div className="h-full flex items-center justify-center w-1/12 lg:hidden">
                <ArrowRight className={arrowButtonClass} onClick={goNext2} />
              </div>
            </div>

            <div className="flex w-full md:hidden">
              <Button
                external
                primary
                full
                outlined
                padding="px-16"
                margin="my-8 mx-auto"
                text="WHAT WE DO"
                to="/services"
              ></Button>
            </div>
          </div>
          <div className="mt-10">
            <FocusSection />
          </div>
          {/* Blog posts */}
          {page.showBlog && postNodes && (
            <div className="inline-block px-4 py-6 pb-12 lg:px-0 relative w-full lg:pb-12 lg:py-10 light-green">
              <div className=" flex flex-col md:flex-row justify-between items-center w-11/12 mx-auto py-4">
                <h2 className="leading-tight font-bold text-5xl w-full md:w-full text-center lg:text-left mb-6 lg:mb-0">
                  Recent Insights
                </h2>
                {/* <div className="hidden w-1/2 justify-end lg:flex">
                  <Button
                    external
                    outlined
                    margin="mb-8 md:mb-0"
                    text="White Papers + Newsletters"
                    to="/insights"
                  ></Button>
                </div> */}
              </div>

              <div className="flex relative items-center w-full">
                <div className="h-full flex items-center justify-center w-1/12">
                  <ArrowLeft className={arrowButtonClass} onClick={goPrev3} />
                </div>
                <div className="h-full mx-auto w-9/12 lg:w-11/12">
                  <Swiper {...params3}>
                    {postNodes.map((node) => (
                      <BlogPostPreview length={50} {...node} key={node.id} />
                    ))}
                  </Swiper>
                </div>
                <div className="h-full flex items-center justify-center w-1/12">
                  <ArrowRight className={arrowButtonClass} onClick={goNext3} />
                </div>
              </div>

            </div>
          )}
        </div>

      </Container>
    </Layout>
  );
};

export default IndexPage;
