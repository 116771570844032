import React, { useEffect, useState } from 'react';

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const Marquee = ({ hoveredButton, brands }) => {
  const [logoSet, setLogoSet] = useState([]);
  const [marqueeDuration, setMarqueeDuration] = useState(0);

  useEffect(() => {
    const type = hoveredButton === "button1" ? "food" :
      hoveredButton === "button2" ? "beauty" :
        hoveredButton === "button3" ? "health" :
          null;

    const newLogoSet = type
      ? brands.filter(logo => logo.type === type)
      : brands;

    setLogoSet(newLogoSet);

    // Update the duration based on the number of items in the logo set
    const duration = newLogoSet.length > 0 ? Math.max(5, newLogoSet.length * 1) : 10;
    setMarqueeDuration(duration);

  }, [hoveredButton]);

  const getBackgroundColorClass = () => {
    switch (hoveredButton) {
      case "button1":
        return "bg-blue-500";
      case "button2":
        return "bg-green-500";
      case "button3":
        return "bg-red-500";
      default:
        return "bg-orange-500";
    }
  };

  return (
    <div className="relative z-20">
      <div className={`${getBackgroundColorClass()} inline-block relative w-full overflow-hidden`}>
        <div className={`marquee relative flex flex-row flex-no-wrap`}>
          {/* Repeat the logo set to create the marquee effect */}
          {Array(4).fill().map((_, index) => (
            <div
              key={index}
              className="marqueeLine w-full"
              style={{
                animationDuration: `${marqueeDuration}s`, // Apply the dynamic duration here
              }}
            >
              {logoSet.map((logo, logoIndex) => {
                if (logo && logo.logo && logo.logo.asset)
                  return (
                    <img
                      key={logoIndex}
                      className="w-20 marqueeItem object-contain"
                      src={imageUrlFor(buildImageObj(logo.logo)).url()}
                      alt={`Logo ${logoIndex}`}
                    />
                  )
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Marquee;
